import React from "react"
import { Flex } from 'theme-ui';
import City from '../common/city';

const Cities = () => {
  return (
    <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              p: 30,
              textAlign: 'center',
            }}
          >
            <City
              text={'View All'}
              href={
                '/'
              }
        />
                    <City
              text={'Boston'}
              href={
                '/boston'
              }
        />
                    <City
              text={'Philadelphia'}
              href={
                '/philadelphia'
              }
        />
          </Flex>
  )}

export default Cities;
