/**@jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { motion } from 'framer-motion';
import { useState } from 'react';

const Button = ({ text, href }) => {
  const [isHovered, setHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '25px',
      }}
    >
      <motion.a href={href} sx={{ variant: 'a.button' }}>
        <p>{text}</p>
      </motion.a>
      <div
        sx={{
          variant: 'a.button',
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          borderTopRightRadius: '3px',
          borderBottomRightRadius: '3px',
          backgroundColor: 'primaryDark',
        }}
      >
        <motion.div
          animate={{ x: isHovered ? 10 : 0 }}
          transition={{
            yoyo: isHovered ? Infinity : null,
            duration: 0.5,
          }}
        >
          >
        </motion.div>
      </div>
    </div>
  );
};

export default Button;
